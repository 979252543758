import React, {Component} from 'react';

class SecurityPrice extends Component {
    constructor(props){
        super(props);
        this.state= {
            list: [
                {
                    id: 1,
                    adTitle: "Professional Planning",
                    icon: 'icon_check_alt2',
                    icon2: 'icon_check_alt2',
                    icon3: 'icon_check_alt2',
                    icon4: 'icon_check_alt2',
                    icon5: 'icon_check_alt2',

                },
                {
                    id: 2,
                    adTitle: "Light Switch",
                    icon: 'icon_check_alt2',
                    icon2: 'icon_check_alt2',
                    icon3: 'icon_check_alt2',
                    icon4: 'icon_check_alt2',
                    icon5: 'icon_check_alt2',
                },
                {
                    id: 3,
                    adTitle: "Control Hub",
                    icon: 'icon_check_alt2',
                    icon2: 'icon_check_alt2',
                    icon3: 'icon_check_alt2',
                },
                {
                    id: 4,
                    adTitle: "Infrared Controller",
                    icon: 'icon_check_alt2',
                    icon2: 'icon_close',
                    icon3: 'icon_check_alt2',
                },
                {
                    id: 5,
                    adTitle: "Wi-Fi Access Point",
                    icon: 'icon_check_alt2',
                    icon2: 'icon_check_alt2',
                    icon3: 'icon_check_alt2',
                },
                {
                    id: 6,
                    adTitle: "Configuration",
                    icon: 'icon_check_alt2',
                    icon2: 'icon_close',
                    icon3: 'icon_check_alt2',
                },
                {
                    id: 7,
                    adTitle: "Installation",
                    icon: 'icon_check_alt2',
                    icon2: 'icon_check_alt2',
                    icon3: 'icon_check_alt2',
                },
                {
                    id: 8,
                    adTitle: "On-Site Walkthrough",
                    icon: 'icon_check_alt2',
                    icon2: 'icon_close',
                    icon3: 'icon_check_alt2',
                }
            ]
        }
    }
    render(){
        return(
            <section className="pricing_area_four sec_pad">
            <div className="container">
                <div className="hosting_title security_title text-center">
                    <h2 className="wow fadeInUp" data-wow-delay="0.2s"><span>Smart Home Package</span> Free Planning and Expert Advice</h2>
                </div>
                <div className="price_info_two price_info_three">
                    <div className="price_head">
                        <div className="p_head time">
                            <h4><b>Smart Home Package</b></h4>
                        </div>
                        <div className="p_head">
                            <h5>Basic Package</h5>
                            <p>$0.0</p>
                        </div>
                        <div className="p_head">
                            <h5>Essential Package</h5>
                            <p>$30.0 / mo</p>
                        </div>
                        <div className="p_head">
                            <h5>Complete Package</h5>
                            <p>$40.0 / mo</p>
                        </div>
                        <div className="p_head">
                            <h5>Multi-Storey Package</h5>
                            <p>$40.0 / mo</p>
                        </div>
                        <div className="p_head">
                            <h5>Customization Package</h5>
                            <p>$40.0 / mo</p>
                        </div>
                    </div>
                    <div className="price_body">
                        {
                            this.state.list.map( list => {
                                return(
                                    <div className="pr_list" key={list.id}>
                                        <div className="price_item">
                                        <h5 className="pr_title" data-toggle="tooltip" data-placement="top" title="" data-original-title="Item List">{list.adTitle}</h5>
                                        </div>
                                        <div className="price_item" data-title="Basic">
                                            <h5 className="check"><i className={list.icon}></i></h5>
                                        </div>
                                        <div className="price_item" data-title="Enterprise">
                                            <h5 className="check"><i className={list.icon2}></i></h5>
                                        </div>
                                        <div className="price_item" data-title="Unlimited">
                                            <h5 className="check"><i className={list.icon3}></i></h5>
                                        </div>
                                        <div className="price_item" data-title="Unlimited">
                                            <h5 className="check"><i className={list.icon4}></i></h5>
                                        </div>
                                        <div className="price_item" data-title="Unlimited">
                                            <h5 className="check"><i className={list.icon5}></i></h5>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <div className="pr_list">
                            <div className="price_item">
                            </div>
                            <div className="price_item">
                                <a href="/#" className="price_btn btn_hover">Find out more</a>
                            </div>
                            <div className="price_item">
                                <a href="/#" className="price_btn btn_hover">Find out more</a>
                            </div>
                            <div className="price_item">
                                <a href="/#" className="price_btn btn_hover">Find out more</a>
                            </div>
                            <div className="price_item">
                                <a href="/#" className="price_btn btn_hover">Find out more</a>
                            </div>
                            <div className="price_item">
                                <a href="/#" className="price_btn btn_hover">Find out more</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        )
    }
}
export default SecurityPrice;