import React from 'react';

const Analyticeslist =()=>{
    return(
        <section className="analytices_list_area security_list_area">
            <img className="macbook_a" src={require ("../img/home-security/mackbook.png")} alt=""/>
            <img className="lock" src={require ("../img/home-security/padlock.png")} alt=""/>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="hosting_title analytices_title">
                            <h2><span>Protect what’s</span>yours with Network Security</h2>
                            <p>We're dedicated to helping you protect your online privacy and security. Wi-Fi security is one of the most important aspects of keeping your online presence secure,
                                and we're here to help. </p>
                        </div>
                        <ul className="analytices_list">
                            <li>Extended Wi-Fi Coverage</li>
                            <li>Improved Wi-Fi Performance</li>
                            <li>Scalability</li>
                            <li>Network Security</li>
                            <li>Multiple SSIDs</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Analyticeslist;