import React from 'react';
import SecurityFeaturesItem from './SecurityFeaturesItem';

const SecurityFeatures =()=>{
    return(
        <section className="h_security_area">
            <div className="container">
                <div className="hosting_title security_title text-center">
                    <h2 className="wow fadeInUp" data-wow-delay="0.2s"><span>Network Security</span> Total Digital Protection</h2>
                </div>
                <div className="row">
                    <SecurityFeaturesItem itemClass="pr_70" image="security_1.png" Title="Wi-Fi Access Point For All Rooms " pText="Maximize your Wi-Fi coverage with our access points, providing reliable connectivity in every room of your home or office"/>
                    <SecurityFeaturesItem itemClass="pl_70" image="security_2.png" Title="Advanced Wi-Fi Traffic Control" pText="Take control of your network traffic with our advanced Wi-Fi management solutions, optimizing performance for all your devices"/>
                    <SecurityFeaturesItem itemClass="pr_70" image="security_3.png" Title="Track Wi-Fi Usage Easily" pText="Easily monitor and track your Wi-Fi usage with intuitive User Interface, providing real-time insights and control over your network activity"/>
                    <SecurityFeaturesItem itemClass="pl_70" image="security_4.png" Title="Malicious Website Protection" pText="Protect your devices from malicious websites with our advanced security solutions, safeguarding your online activities and personal information"/>
                </div>
            </div>
        </section>
    )
}
export default SecurityFeatures;