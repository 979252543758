import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import DesignBanner from '../components/Banner/DesignBanner';
import Service from '../components/Service/Service';
import Subscribe from '../components/Subscribe';
import Footer from '../components/Footer/Footer';
import FooterData from '../components/Footer/FooterData';
import SecurityBanner from "../components/Banner/SecurityBanner";
import SecurityFeatures from "../components/Features/SecurityFeatures";
import SecurityPowerFeatures from "../components/Features/SecurityPowerfulFeatures";
import SecurityService from "../components/Service/SecurityService";
import SequrityPrice from "../components/Features/SecurityPrice";
import Analyticeslist from "../components/Analyticeslist";
import SecurityCustomerLogo from "../components/SecurityCustomersLogo";
import SecurityIntegration from "../components/SecurityIntegration";
import SecurityAction from "../components/SecurityAction";
import FooterSecurity from "../components/Footer/FooterSecurity";

export const Home = () => (
    <div className="body_wrapper">
            <CustomNavbar mClass="menu_eight" nClass="w_menu" slogo="sticky_logo" hbtnClass="security_btn"/>
            <SecurityBanner/>
            <SecurityFeatures/>
            <SecurityPowerFeatures/>
            {/*<SecurityService/>*/}
            <SequrityPrice/>
            <Analyticeslist/>
            {/*<SecurityCustomerLogo/>*/}
            <SecurityIntegration/>
            {/*<SecurityAction/>*/}
            <Footer FooterData={FooterData}/>
    </div>
)