import React from 'react';
const SecurityIntegration =()=>{
    return(
        <section className="security_integration_area">
            <div className="container">
                <div className="hosting_title analytices_title text-center">
                    <h2 className="wow fadeInUp"><span>Discover just how</span>
                        Awesome Control Can Be.</h2>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-4 col-sm-6">
                        <a href="/#" className="s_integration_item">
                            <img src={require("../img/new-home/kissmetrics.png")} alt=""/>
                            <h5>Lights</h5>
                        </a>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                        <a href="/#" className="s_integration_item">
                            <img src={require("../img/new-home/metorik.png")} alt=""/>
                            <h5>Curtains</h5>
                        </a>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                        <a href="/#" className="s_integration_item">
                            <img src={require("../img/new-home/nicereply-1.png")} alt=""/>
                            <h5>Air-Conditioners</h5>
                        </a>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                        <a href="/#" className="s_integration_item">
                            <img src={require("../img/new-home/update.png")} alt=""/>
                            <h5>Fans</h5>
                        </a>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                        <a href="/#" className="s_integration_item">
                            <img src={require("../img/new-home/campfire.png")} alt=""/>
                            <h5>Robot Vacuum</h5>
                        </a>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                        <a href="/#" className="s_integration_item">
                            <img src={require("../img/new-home/webhooks.png")} alt=""/>
                            <h5>CCTV</h5>
                        </a>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                        <a href="/#" className="s_integration_item">
                            <img src={require("../img/new-home/wedsite.png")} alt=""/>
                            <h5>Door Lock</h5>
                        </a>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
                        <a href="/#" className="s_integration_item">
                            <img src={require("../img/new-home/briteverify.png")} alt=""/>
                            <h5>Door Bell Camera</h5>
                        </a>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center">
                <a href="/#" className="security_app_btn wow fadeInLeft"><i className="fab fa-android"></i>Compatible with <span>Android Phone</span></a>
                <a href="/#" className="security_app_btn wow fadeInRight"><i className="fab fa-apple"></i>Compatible with <span>iPhone</span></a>
            </div>
        </section>
    )
}
export default SecurityIntegration;