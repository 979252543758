import React from 'react';
import Sectitle from "../../Title/Sectitle";
import Tabitem from "./CloudServiceTabItem";

const CloudServiceTab =()=>{
    return(
        <section className="software_service_area sec_pad">
            <div className="container">
                <Sectitle Title="Seamlessly go from deploying to scaling." TitleP="Tickety-boo cheers plastered twit chimney pot off his nut hotpot spend a penny have it, so I said squiffy A bit of how's your father."
                 sClass="sec_title text-center mb_70"/>
                <div className="row">
                    <div className="col-lg-3 col-md-3">
                        <ul className="nav nav-tabs software_service_tab" id="myTab" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" id="de-tab" data-toggle="tab" href="#de" role="tab" aria-controls="de" aria-selected="true">Extended Wi-Fi Coverage</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="saas-tab" data-toggle="tab" href="#saas" role="tab" aria-controls="saas" aria-selected="false">Wi-Fi Performance</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="ma-tab" data-toggle="tab" href="#ma" role="tab" aria-controls="ma" aria-selected="true">Scalability</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="secure-tab" data-toggle="tab" href="#secure" role="tab" aria-controls="secure" aria-selected="false">Network Security</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="scale-tab" data-toggle="tab" href="#scale" role="tab" aria-controls="scale" aria-selected="true">Multiple SSIDs</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="monitor-tab" data-toggle="tab" href="#monitor" role="tab" aria-controls="monitor" aria-selected="false">Intuitive User Interface</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-9 col-md-9">
                        <div className="tab-content software_service_tab_content">
                            <div className="tab-pane fade show active" id="de" role="tabpanel" aria-labelledby="de-tab">
                                <div className="row">
                                    <Tabitem ticon="ti-user" text="Better Connectivity" description="With extended wifi coverage, you can enjoy better connectivity throughout your home or office. You can connect more devices, and the signal strength and speed of your internet will be stronger"/>
                                    <Tabitem colClass="offset-lg-2" ticon="ti-basketball" text="Increased Productivity" description="With a stronger wifi signal, you can work more efficiently, stream videos without buffering, and have faster downloads. This can increase productivity and save time."/>
                                    <Tabitem ticon="ti-face-smile" text="Improved Entertainment Experience" description="With extended wifi coverage, you can enjoy seamless streaming of your favorite TV shows and movies without interruption. You can also play online games without any lag or buffering"/>
                                    <Tabitem colClass="offset-lg-2" ticon="ti-harddrives" text="Better Home Automation" description="Extended wifi coverage can enhance your home automation experience, allowing you to control your smart devices from anywhere in your home. You can control your lights, thermostat, and security system with ease, making your home more comfortable and secure"/>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="saas" role="tabpanel" aria-labelledby="saas-tab">
                                <div className="row">
                                    <Tabitem ticon="ti-face-smile" text="Faster Internet Speeds" description="One of the primary benefits of improved wifi performance is faster internet speeds. This means that you can download files, stream videos, and browse the web more quickly"/>
                                    <Tabitem colClass="offset-lg-2" ticon="ti-harddrives" text="Better Signal Strength" description="Improved wifi performance also means better signal strength. This means that you can enjoy a more reliable connection and fewer dropped signals"/>
                                    <Tabitem ticon="ti-user" text="Increased Range" description="With improved wifi performance, you can also enjoy increased range. This means that you can connect to your wifi network from further away, allowing you to work or play from anywhere in your home or office"/>
                                    <Tabitem colClass="offset-lg-2" ticon="ti-basketball" text="Improved Security" description="Improved wifi performance can also improve the security of your network. With a stronger signal, you can ensure that your network is more secure and less susceptible to unauthorized access or hacking attempts"/>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="ma" role="tabpanel" aria-labelledby="ma-tab">
                                <div className="row">
                                    <Tabitem ticon="ti-user" text="Team Management" description=""/>
                                    <Tabitem colClass="offset-lg-2" ticon="ti-basketball" text="Global Availability" description=""/>
                                    <Tabitem ticon="ti-face-smile" text="Cluster Deployments" description=""/>
                                    <Tabitem colClass="offset-lg-2" ticon="ti-harddrives" text="Backups & Snapshots" description=""/>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="secure" role="tabpanel" aria-labelledby="secure-tab">
                                <div className="row">
                                    <Tabitem ticon="ti-user" text="Team Management" description=""/>
                                    <Tabitem colClass="offset-lg-2" ticon="ti-basketball" text="Global Availability" description=""/>
                                    <Tabitem ticon="ti-face-smile" text="Cluster Deployments" description=""/>
                                    <Tabitem colClass="offset-lg-2" ticon="ti-harddrives" text="Backups & Snapshots" description=""/>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="scale" role="tabpanel" aria-labelledby="scale-tab">
                                <div className="row">
                                    <Tabitem ticon="ti-user" text="Team Management" description=""/>
                                    <Tabitem colClass="offset-lg-2" ticon="ti-face-smile" text="Cluster Deployments" description=""/>
                                    <Tabitem ticon="ti-harddrives" text="Backups & Snapshots" description=""/>
                                    <Tabitem colClass="offset-lg-2" ticon="ti-basketball" text="Global Availability" description=""/>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="monitor" role="tabpanel" aria-labelledby="monitor-tab">
                                <div className="row">
                                    <Tabitem ticon="ti-user" text="Team Management" description=""/>
                                    <Tabitem colClass="offset-lg-2" ticon="ti-basketball" text="Global Availability" description=""/>
                                    <Tabitem ticon="ti-face-smile" text="Cluster Deployments" description=""/>
                                    <Tabitem colClass="offset-lg-2" ticon="ti-harddrives" text="Backups & Snapshots" description=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default CloudServiceTab;