import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import PrototypeService from '../components/Service/Sservice/PrototypeService';
import Partner from '../components/Partner';
import ServiceSubscribe from '../components/ServiceSubscribe';
import ServiceData from '../components/Service/ServiceData';
import FooterData from '../components/Footer/FooterData';
import Footer from "../components/Footer/Footer";
import SmartHomeService from "../components/Service/SmartHomeService";

const SmartHome = () => {
    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Smart Home Services" Pdescription="Building A Smarter Home Via Automation"/>
            <SmartHomeService ServiceData={ServiceData}/>
            <PrototypeService/>
            {/*<Partner pClass="partner_logo_area_two" pClasst="pt-0 mb-0"/>*/}
            <ServiceSubscribe sClass="s_form_info_two"/>
            <Footer FooterData={FooterData}/>
        </div>
    )
}
export default SmartHome;