import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import HRService from '../components/Service/HRService';
import PrototypeService from '../components/Service/Sservice/PrototypeService';
import Partner from '../components/Partner';
import ServiceSubscribe from '../components/ServiceSubscribe';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import Footer from "../components/Footer/Footer";
import SmartWifiService from "../components/Service/SmartWifiService";
import CloudService from "../components/Service/CloudService";
import CloudServiceTab from "../components/Service/Sservice/CloudServiceTab";
import CloudFeaturesTwo from "../components/Features/CloudFeaturesTwo";

const Wifi = () => {
    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Our services" Pdescription=""/>
            {/*<PrototypeService/>*/}
            {/*<Partner pClass="partner_logo_area_two" pClasst="pt-0 mb-0"/>*/}
            <CloudService/>
            <CloudServiceTab/>
            <CloudFeaturesTwo/>
            <ServiceSubscribe sClass="s_form_info_two"/>
            <Footer FooterData={FooterData}/>
        </div>
    )
}
export default Wifi;