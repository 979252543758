import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import SecurityPrice from '../components/Features/SecurityPrice';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import FooterSecurity from "../components/Footer/FooterSecurity";
import Footer from "../components/Footer/Footer";
// import SecurityPrice from "../components/Features/SecurityPrice";

const Price = () => {
    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Pricing Plan" Pdescription=""/>
            <SecurityPrice/>
            <Footer FooterData={FooterData}/>
        </div>
    )
}
export default Price;